<template>
  <v-container fluid>
    <common-error
      v-if="isError"
      @fetchData="invoiceCommonData"
    />
    <section v-else-if="isLoading">
      <v-row v-for="skeleton in 8" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <v-sheet v-else rounded class="pa-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Gambar</span></v-subheader>
          </v-col>
          <v-col v-if="!form.imageUrl">
            <v-file-input
              :clearable="false"
              :rules="rules.imageUrl"
              ref="imageInput"
              type="file"
              accept="image/*"
              @change="setFileInput"
              v-model="formUpload.file"
            />
          </v-col>
          <v-col :cols="12" sm="4" v-else>
            <v-card
              class="mx-auto"
              outlined
            >
              <v-img
                :src="form.imageUrl"
                contain
                aspect-ratio="1"
                height="200"
                lazy-src="../../assets/images/placeholder-image.png"
              >
              </v-img>
              <v-card-actions class="white">
                <v-btn
                  color="red"
                  small
                  block
                  class="pa-4 white--text"
                  @click="deleteFile"
                  :loading="formUpload.isLoading"
                >
                  Hapus Gambar
                  <v-icon
                    right
                    dark
                  >
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Nama Bank</span></v-subheader>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.bankName"
              :rules="rules.nameBank"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">A/C</span></v-subheader>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.bankAccountName"
              :rules="rules.aCLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">A/N</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              v-model="form.bankAccountNumber"
              :rules="rules.aNLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Penanda Tangan 1</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.firstSignerName"
              :rules="rules.signerOneLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Jabatan Penanda Tangan 1</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.firstSignerPosition"
              :rules="rules.signatoryOneLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Penanda Tangan 2</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.secondSignerName"
              :rules="rules.signerTwoLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Jabatan Penanda Tangan 2</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.secondSignerPosition"
              :rules="rules.signatoryTwoLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="userAccess.canCreate">
          <v-col>
            <v-btn elevation="2" color="primary" class="float-right" @click="submit">
              <b>{{ $_strings.common.SAVE }}</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'invoice-data-form',
  data() {
    return {
      isLoading: false,
      isError: false,
      formUpload: {
        fileName: '',
        isLoading: false,
        file: null,
        base64: null,
      },
      valid: false,
      form: {
        imageUrl: '',
        bankName: '',
        bankAccountNumber: '',
        bankAccountName: '',
        firstSignerName: '',
        firstSignerPosition: '',
        secondSignerName: '',
        secondSignerPosition: '',
      },
      rules: {
        imageUrl: [
          (v) => !!this.form.imageUrl || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.imageUrlLabel),
        ],
        nameBank: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.nameBankLabel),
        ],
        aCLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.aCLabel),
        ],
        aNLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.aNLabel),
        ],
        signerOneLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signatoryOneLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerTwoLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signatoryTwoLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
      },
      fileQueueDeletion: [],
    };
  },
  methods: {
    fileUploadValidation(event, fileSizeMin = 500000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 500000 => 500kb
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > 500000) {
        this.$dialog.notify.error('Gambar Maksimum 500kb.');
        return false;
      }
      return true;
    },
    setFileInput(file) {
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        this.formUpload.base64 = base64Image;
        this.formUpload.fileName = file.name;
        this.form.imageUrl = base64Image;
      };
      reader.readAsDataURL(file);
    },
    async deleteFile() {
      if (!this.formUpload.base64 && this.form.imageUrl) {
        try {
          this.formUpload.isLoading = true;
          const form = {
            url: this.form.imageUrl,
          };
          this.fileQueueDeletion.push(form);
          this.form.imageUrl = null;
          this.formUpload.file = null;
        } finally {
          this.formUpload.isLoading = false;
        }
      } else {
        this.form.imageUrl = null;
        this.formUpload.fileName = null;
        this.formUpload.file = null;
        this.formUpload.base64 = null;
      }
    },
    uploadImage() {
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId } = dataUser;
      const filePath = `company/${companyId}/invoice_data/`;
      const { base64, fileName } = this.formUpload;
      return this.$_services.uploads.uploadImage(base64, fileName, filePath)
        .then((result) => {
          this.form.imageUrl = result.data.url;
          this.formUpload.file = null;
          if (this.fileQueueDeletion.length && this.fileQueueDeletion[0].url === result.data.url) {
            this.fileQueueDeletion = [];
          }
        });
    },
    invoiceCommonData() {
      this.isLoading = true;
      this.isError = false;
      this.$_services.invoiceData.invoiceCommonData()
        .then((res) => {
          this.form = res.data;
        }).catch(() => {
          this.isError = true;
        }).finally(() => {
          this.isLoading = false;
        });
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      if (this.formUpload.base64) {
        try {
          this.$root.$loading.show();
          await this.uploadImage();
        } catch {
          this.$root.$loading.hide();
          return;
        }
      }
      this.$root.$loading.show();
      this.$_services.invoiceData.invoiceUpdateCommonData(this.form)
        .then((res) => {
          if (this.fileQueueDeletion.length) {
            Promise.all(this.fileQueueDeletion.map((payload) => this.$_services.uploads.deleteFile(payload)));
            this.fileQueueDeletion = [];
          }
          this.$dialog.notify.success('Berhasil memperbarui data invoice');
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
  created() {
    this.invoiceCommonData();
  },
};
</script>

<style lang="scss" scoped>
.custom-image {
  color: #b5b5b5;
}
.custom-image::-webkit-file-upload-button {
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  background-color: white;
  color: #2c59c2;
  border-width: 1px;
  border-color: #2c59c2;
}
</style>
